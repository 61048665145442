.testimonialsSlider {
  padding: 20px;
  text-align: center;
  cursor: pointer;
}


.testimonial {
  padding: 50px;
  background: #fff;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
  border: 10px solid #F5F7FB;
  margin: 0px 20px;
  height: 400px;
}

.testimonialContain {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 30px;
}

.testimonialImage {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-bottom: 20px;
}

.testimonialName {
  font-size: 1.2em;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 600;
  color: #144273;
  padding-bottom: 30px;
  letter-spacing: 2px;
}

.testimonialContent {
  display: flex;
  flex-direction: column;
  align-items: center;
}


.testimonialButton {
  float: left;
  margin-top: 10px;
  border-radius: 2px;
  color: #fff;
  padding: 10px 20px;
  font-size: 11px;
  font-weight: 600;
  background-color: black;
  cursor: pointer
}

.testimonialButton:focus {
  box-shadow: black;
}

/* @media only screen and (min-width:1024px) {

  .testimonial {
    height: 500px;
  }

} */

@media only screen and (max-width:800px) {

  .testimonial {
    height: 500px;
  }

}
